import React, { useState } from 'react'
import gsap from 'gsap'
import { GridContainer } from '../GridViewOhpeneers/styled'
import { Box } from '@xstyled/styled-components'
import AnimatedImage from './AnimatedImage'

interface Props {
  gridReady: boolean
  sources: []
  position: string
}

/* eslint-disable indent */
const getRandomOrder = (images) => gsap.utils.shuffle(images)

const GridViewSimpleImage: React.FC<Props> = ({
  sources,
  gridReady,
  position = 'right',
}) => {
  const [imageOrder] = useState(getRandomOrder(sources))
  const rows = imageOrder.reduce((all, one, i) => {
    const ch = Math.floor(i / 4)
    const newAll = all
    newAll[ch] = [].concat(newAll[ch] || [], one)
    return all
  }, [])

  return (
    gridReady && (
      <GridContainer data-url position={position}>
        {rows?.map((row, rowIndex) => (
          <Box row key={rowIndex}>
            {row.map((rowEntry, index) => {
              const { data } = rowEntry
              const staggerBy = rowIndex * 0.4 + index * 0.1
              return (
                data && (
                  <AnimatedImage
                    key={data.url}
                    source={data.url}
                    staggerBy={staggerBy}
                    gridReady={gridReady}
                  />
                )
              )
            })}
          </Box>
        ))}
      </GridContainer>
    )
  )
}

export default GridViewSimpleImage
